

















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { ApiProfile } from '../scripts/apiProfile';
import * as lib from '@/scripts/apiHelper.ts';
import * as rules from '@/scripts/rules.ts';

@Component({
  components: {}
})
export default class ResetPassword extends Vue {
  private show = true;
  private loading = false;
  private errors = '';

  private linkCode = '';

  private formValid = false;
  private passwordCompare = '';
  private newPassword = '';
  private showPwd = false;
  private passwordChanged = false;

  public created(): void {
    this.formValid = false;
  }

  private hideDialog(): void {
    this.show = false;
    // Redirect to front page
    if (this.passwordChanged) {
      window.location.href = '#/Logout';
    } else {
      window.location.href = '/';
    }
  }

  @Watch('$route.query.linkcode', { immediate: true })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private async getLink(newValue: string, oldValue: string): Promise<void> {
    // Get link code from url
    if (newValue) {
      this.linkCode = newValue;
    }
  }

  private async resetPassword(): Promise<void> {
    this.loading = true;
    try {
      await ApiProfile.resetPassword({
        LinkCode: this.linkCode,
        NewPassword: btoa(this.newPassword)
      });
      this.passwordChanged = true;
    } catch (error) {
      this.errors = lib.getErrorMessage(error);
      this.passwordChanged = false;
    } finally {
      this.loading = false;
    }
  }

  get passwordRules(): ((v: string) => boolean | string)[] {
    return rules.passwordRules();
  }

  get passwordCompareRules(): ((v: string) => boolean | string)[] {
    return [
      (v: string): boolean | string => !!v || 'Kodeord skal angives',
      (): boolean | string => this.passwordCompare === this.newPassword || 'Kodeord er ikke ens'
    ];
  }
}
